$('document').ready(function(){

    // Facebook slideshow
    var feed = $('.js__facebook-feed');
    feed.find('.cff-item').hide();

    feed.find('.cff-item').eq(0).show();

    $('.js__next').bind('click', function(){

    });

    var currentShow = 0;
    var countItem = feed.find('.cff-item').length;
    $(".js__facebook-feed .js-next").click(function(e) {
        e.preventDefault()
        feed.find(".cff-item").eq(currentShow).fadeOut(350, function() {
            currentShow++;
            feed.find(".cff-item").eq(currentShow).fadeIn(350, function() {
                if (currentShow == countItem - 1 ) $(this).hide();
            })
        });
    });

    $(".js__facebook-feed .js-prev").click(function(e) {
        e.preventDefault()
        feed.find(".cff-item").eq(currentShow).fadeOut(350, function() {
            currentShow--;
            feed.find(".cff-item").eq(currentShow).fadeIn(350, function() {
                if (currentShow == 0) $(this).hide();
            })
        });
    })


});